import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import Iconify from 'src/components/Iconify';
import axios from 'axios';
import { base_url } from 'src/common';

import { RatingStar } from 'rating-star';
import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FileUploader } from 'react-drag-drop-files';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertFromHTML, convertFromRaw, Modifier } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import 'draft-js/dist/Draft.css';

import { forEach } from 'lodash';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
const useStyle = makeStyles((theme) => ({
  modalMain: {
    width: '530px',

    // height: '530px',

    // backgroundColor: theme.palette.primary.dark,

    border: '1px solid rgba(239, 239, 239, 0.1)',

    borderRadius: '10px',

    borderColor: '#fff',
    padding: '15px 15px',
    '& .css-ypiqx9-MuiDialogContent-root': {
      padding: '15px 15px',
    },
    '& .heading': {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
    },
  },
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '38px',
    color: '#101828',
    paddingBottom: '32px',
  },
  management: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingTop: '32px',
    paddingBottom: '4px',
  },
  body1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
  },
  ParticipantDetails: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingBottom: '4px',
  },
  body2text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
    marginTop: '0px !important',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: '3rem',
    '& img': {
      width: '100%',
    },
    '& .imgBox': {
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      '& img': {
        borderRadius: '50%',
      },
    },
    '& .uploadBox': {
      display: 'flex',
      flex: 1,
      padding: '10px 0px',
      justifyContent: 'center',
      '& label': {
        border: '0px !important',
        flexDirection: 'column !important',
        height: 'unset',
      },
      '& div': {
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        '& span': {
          // '&:last-child': {
          //   color: 'yellow !important',
          // },
        },
      },
    },
  },
  wrapper_class: {
    padding: '1rem',
    border: '1px solid #ccc',
    marginBottom: '1.5rem',
  },
  editor_class: {
    backgroundColor: 'lightgray',
    padding: '1rem',
    border: '1px solid #ccc',
  },
  toolbar_class: {
    border: '1px solid #ccc',
  },
  btncontainer: {
    '& .Mui-selected': {
      color: '#6941C6',
    },
    '& button': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#344054',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '12px',
      marginRight: '2rem',
      border: '0px !important',
      borderRadius: '5px !important',
      '&:focus': {
        color: '#6941C6',
      },
      '& svg': {
        width: '20px !important',
        height: '20px !important',
        marginLeft: '12px',
      },
    },
  },
  addButton: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '144px',
    height: '40px',
    background: '#1570EF',
    border: '1px solid #1570EF',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    '&:hover': {
      background: '#1570EF',
    },
  },
}));

export default function AdminEditCoach(props) {
  const navigate = useNavigate();
  const classes = useStyle();
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();
  const [loadingState, setloadingState] = useState(false);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [ConvertedContent, setConvertedContent] = useState(null);
  const [allClients, setallClients] = useState([]);
  const [clientval, setclientVal] = useState([]);
  const [qualificationval, setqualificationval] = useState([]);
  
  const [file, setFile] = useState(null);
  const [Paths, setPaths] = useState(null);
  const [companyVal, setcompanyVal] = useState({});
  const [yearsOfExp, setYearsOfExp] = useState(location?.state?.coach_profile?.years_of_experience);
  const [allCompanies, setallCompanies] = useState([]);
  const [allQualifications, setallQualifications] = useState([]);
  const [companyID, setcompanyID] = useState(null);
  const [allSectors, setallSectors] = useState([]);

  const [rating, setRating] = useState([]);

  const [alignment, setAlignment] = useState(0);
  const [detailsArray, setDetailsArray] = useState([0]);
  const [testimonialLoading, setTestimonialLoading] = useState(false);
  let testimonialDelete = false;  
  
  //testimonial picture
  const [files, setFiles_t] = useState([null, null, null, null, null]);
  const [paths, setPaths_t] = useState([null, null, null, null, null]);

  const [expLabel, setExpLabel] = useState([
    { key: 0, label: '<3 years' },
    { key: 1, label: '3-5 years' },
    { key: 2, label: '5-10 years' },
    { key: 3, label: '>10 years' },
  ]);
  const detailObject = {
      rating: '',
      test_name: '',
      role: '',
      testimonial:'',
    };
  var formData = new FormData();
  const token = sessionStorage.getItem('libra_admin_token');
  const onRatingChange = (val) => {
      // setRating(val);
      // console.log(typeof val);
      setFieldValue(`detail[${alignment}].rating`, val);
    };  
  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Enter proper email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    // client_id: Yup.array().required('Client name is required').min(1, 'Client field must have atleast 1 item'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),

    phone_number: Yup.string().matches(/^\d+$/, 'Phone have digits only').required('Phone no. is required'),
    qualifications: Yup.array().required('Qualifications field is required').min(1, 'Qualifications field is required'),
    universities: Yup.string().required('University is required'),
    industry_experience_by_sector: Yup.array().required('Industry experience is required').min(1, 'Industry experience is required'),
    sector_experience: Yup.string().required('Sector experience is required'),
  });
  const mapCoachTestimonialToDetail = (coachTestimonialArray) => {
    return coachTestimonialArray.map(coachTestimonial => ({
      rating: coachTestimonial.rating,
      test_name: coachTestimonial.name,
      role: coachTestimonial.role,
      testimonial: coachTestimonial.testimonial,
      picture: `${base_url}/storage/${coachTestimonial.picture}`
    }));
  };

  const formik = useFormik({
    initialValues: {
      name: location?.state?.name,
      email: location?.state?.email,
      password: '',
      // client_id: location?.state?.clients,
      password_confirmation: '',
      phone_number: location?.state?.phone_number,
      // profile_picture: null,
      // qualifications: location?.state?.coach_qualification?.qualification_id,
      qualifications:[],
      universities: location?.state?.coach_profile?.universities,
      sector_experience: location?.state?.coach_profile?.sector_experience,
      biography: '',
      years_of_experience: location?.state?.coach_profile?.years_of_experience,
      industry_experience_by_sector: [],
      coaching_approaches: location?.state?.coach_profile?.coaching_approaches,
      functional_areas_of_experience: location?.state?.coach_profile?.functional_areas_of_experience,
      testimonials_number:0,
      detail: mapCoachTestimonialToDetail(location?.state?.coach_testimonial || [])
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      const clients = [];
      const qualifications = [];
      
      def?.map((item) => {
        clients.push(item?.id);
      });
      qual?.map((item) => {
        qualifications.push(item?.id);
      });
      
      console.log('clients', clients);
      setloadingState(true);
      formData.append('_method', 'put');
      formData.append('name', values?.name);
      formData.append('email', values?.email);
      formData.append('password', values?.password);
      formData.append('password_confirmation', values?.password_confirmation);
      formData.append('phone_number', values?.phone_number);
      formData.append('qualifications', qualifications);
      formData.append('universities', values?.universities);
      formData.append('sector_experience', values?.sector_experience);

      values?.biography && formData.append('biography', values?.biography);
      formData.append('client_ids', clients);
      file && formData.append('profile_picture', file);
      formData.append('coaching_approaches', values?.coaching_approaches);
      formData.append('functional_areas_of_experience', values?.functional_areas_of_experience);
      formData.append('testimonials_number', Math.floor(parseInt(values?.detail?.length)));
      formData.append('years_of_experience', values?.years_of_experience);
      formData.append('industry_experience_by_sector', sectors.map((item) => {
        return item.id
      }));

      for (let i = 0; i < values?.detail?.length; i++) {
        formData.append(`rating[${i}]`, values?.detail[i]?.rating);
        formData.append(`test_name[${i}]`, values?.detail[i]?.test_name);
        formData.append(`role[${i}]`, values?.detail[i]?.role);
        formData.append(`testimonial[${i}]`, values?.detail[i]?.testimonial);
        formData.append(`picture[${i}]`, files[i]);
      }

      console.log('values?.client_id', values);
      axios
        .post(`${base_url}/api/coaches/${location?.state?.id}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log("REsponse",res);
          if (res?.data?.status === true) {
            toast.success('Updated successfully');
            clearData();
            setTimeout(() => {
              clearData();
              navigate('/dashboard/coaches', { replace: true });
              setloadingState(false);
            }, 2000);
          }
        })
        .catch((err) => {
          setloadingState(false);
          console.log("REsponse",err);

          if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          } else {
            toast.error('Server Error');
          }
        });
    },
  });
  // console.log('location?.state?.id', location?.state?.id);
  const { errors, touched, handleBlur, setFieldValue, resetForm, handleSubmit, getFieldProps } = formik;
  const getAllCompanies = async () => {
    await axios
      .get(`${base_url}/api/companies`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        // console.log(location?.state?.coach_profile?.years_of_experience);
        // "user_company": {
        //             "company_id": 2,
        //             "user_id": 62
        //         }
        setallCompanies(res?.data?.data?.companies);
        const companies = res?.data?.data?.companies?.find(
          (item) => item.id === location?.state?.user_company?.company_id
        );
        setcompanyVal(companies);
        setFieldValue('company_id', companies?.id);
        // console.log(formik.)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllQualifications = async () => {
    await axios
      .get(`${base_url}/api/qualifications`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallQualifications(res?.data?.data?.qualifications);
        setFieldValue('qualifications',res?.data?.data?.qualifications)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getClients = async () => {
    await axios
      .get(`${base_url}/api/clients`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        // console.log('rescddddddd', res?.data?.data?.clients);
        setallClients(res?.data?.data?.clients);
        // const clientID = res?.data?.data?.clients?.find(
        //   (item) => item.id === location?.state?.coach_profile?.client_id
        // );
        // setclientVal(location?.state?.clients);
        // setFieldValue('client_ids', location?.state?.clients);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllSectors = async () => {
    await axios
      .get(`${base_url}/api/sectors`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallSectors(res?.data?.data?.sectors);
        setFieldValue('industry_experience_by_sector', res?.data?.data?.sectors);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    // setYearsOfExp(location?.state?.coach_profile?.years_of_experience);
    const yearsOfExperience = location?.state?.coach_profile?.years_of_experience;
    if (yearsOfExperience) {
      const matchingOption = expLabel.find(option => option.label === yearsOfExperience);
      // console.log("i am here");
      setYearsOfExp(matchingOption || null);
    }
    getAllCompanies();
    getClients();
    getAllQualifications();
    getAllSectors();
  }, []);
  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(location?.state?.coach_profile?.biography))
      )
    );
    setFieldValue('biography', location?.state?.coach_profile?.biography);
  }, []);
  const fileTypes = ['JPEG', 'PNG', 'JPG'];

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };
  const handleTestimonialDelete = (id) => {
    var newArray = [...detailsArray];
    newArray.splice(id, 1);
    detailsArray.length > 1 && setDetailsArray(newArray);
    var newDetailsArray = [...formik.values.detail];
    newDetailsArray.length > 1 && newDetailsArray.splice(id, 1);

    if (formik.values.detail.length > 1) {
      formik.setFieldValue('detail', newDetailsArray);
      setAlignment(0);
    }
  };
  const handleAlignment = (e, newAlignment) => {
    if (testimonialDelete) {
      testimonialDelete = false;
    } else {
      setAlignment(newAlignment);
    }
  };
  const handleNewToggleButton = () => {
    setTestimonialLoading(true);
    detailsArray.length <= 4 && setDetailsArray([...detailsArray, detailsArray.length]);
    const detailarray = [...formik.values.detail];
    detailarray.push(detailObject);
    formik.values.detail.length <= 4 && formik.setFieldValue('detail', detailarray);
    setTestimonialLoading(false);
  };
  const handleChangeTestimonialPicture = (file, index) => {
    console.log(index);
    const updatedFiles = [...files];
    const updatedPaths = [...paths];

    updatedFiles[index] = file;
    updatedPaths[index] = URL.createObjectURL(file);
    setFieldValue(`detail[${index}].picture`,updatedPaths[index] )
    setFiles_t(updatedFiles);
    setPaths_t(updatedPaths);
  };
  const clearData = () => {
    resetForm();
  };
  const handleChange = (file) => {
    setFile(file);
    setPaths(URL.createObjectURL(file));
    // setFieldValue('profile_picture', file?.File);
  };
  function onEditorStateChange(state) {
    setEditorState(state);
    convertContentToHTML(state);
  }
  const convertContentToHTML = (state) => {
    let currentContentAsHTML = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return (
            <a href={entity.data.url} target={entity?.data?.targetOption}>
              {originalText}
            </a>
          );
        }
        return originalText;
      },
    })(state.getCurrentContent());

    setConvertedContent(currentContentAsHTML);
    setFieldValue('biography', currentContentAsHTML);
  };
  const handlePastedText = (text, html, editorState) => {
    if (html) {
      const blocksFromHTML = convertFromHTML(html);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const newState = EditorState.push(editorState, contentState, 'insert-fragment');
      setEditorState(newState);
      convertContentToHTML(newState);
      return true;
    }
    return false;
  };
  useEffect(() => {
    let arr = [];
    for (let i = 0; i < location?.state?.coach_testimonial?.length; i++) {
      arr.push(i);
    }
    setDetailsArray(arr);
  }, []);
  useEffect(() => {
    const selectedCompanyData = async () => {
      await axios
        .get(`${base_url}/api/companies/${companyID}/users`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setallClients(res?.data?.data?.clients);
          setclientVal([]);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    selectedCompanyData();
  }, [companyID]);
  const [def, setDef] = useState([]);
  const [qual, setQual] = useState([]);
  const [sectors, setSectors] = useState([]);
  useEffect(() => {
    let arr = [];
    allClients?.filter((item) =>
      location?.state?.clients?.map((inner) => {
        console.log('inner', inner?.id == item?.id);
        if (inner?.id == item?.id) {
          arr.push(inner);
        }
      })
    );
    setDef(arr);
  }, [allClients]);

  useEffect(() => {
    let arr = [];
    // console.log(allQualifications);
    // console.log(location?.state?.coach_qualification);
    allQualifications?.filter((item) =>
      location?.state?.coach_qualification?.map((inner) => {
        console.log('inner', inner?.qualification_id , item?.id);
        if (inner?.qualification_id == item?.id) {
          arr.push(item);
        }
      })
    );
    setQual(arr);
  }, [allQualifications]);

  useEffect(() => {
    let arr = [];
    console.log(allSectors);
    console.log(location?.state);
    allSectors?.filter((item) =>
      location?.state?.coach_sector?.map((inner) => {
        console.log('inner', inner?.sector_id , item?.id);
        if (inner?.sector_id == item?.id) {
          arr.push(item);
        }
      })
    );
    setSectors(arr);
  }, [allSectors]);
  // console.log('def', def);

  return (
    <div>
      <Typography variant="h1" className={classes.heading}>
        Coach
      </Typography>
      <Divider />
      <Typography variant="body1" className={classes.management}>
        Overview
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        This is where you can manage the coach.
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
                marginTop: '0px !important',
              }}
            >
              <Box flexDirection={isMobile ? "column" : "row"} className={classes.imageContainer}>
                <Box className="imgBox">
                  <img
                  style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'cover' }}
                     src={
                      location.state.profile_picture != null
                        ? `${base_url}/storage/${location?.state?.profile_picture}`
                        : '/static/icons/Avatar.png'
                    }
                    // src={
                    //   Paths
                    //     ? `${Paths}`
                    //     : `https://libra-mentorings.s3.eu-west-2.amazonaws.com/${location?.state?.profile_picture}`
                    // }
                    // src={
                    //   location.state.profile_picture != null
                    //     ?
                    //     : '/static/icons/Avatar.png'
                    // }
                    // src="http://ec2-54-237-56-13.compute-1.amazonaws.com/public/storage/users/profile_images/fBm0o7vi4X6pSGwIN9TyMJm3paANqjdMw2y9cd33.png"
                    alt="coach"
                  />
                </Box>
                <Box className="uploadBox">
                  <Box sx={{ textAlign: 'center' }}>
                    <FileUploader handleChange={handleChange} types={fileTypes} />
                    <p>{file && `Image name: ${file?.name}`}</p>
                  </Box>
                </Box>
              </Box>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={companyVal}
                options={allCompanies}
                onChange={(e, value) => {
                  formik.resetForm();
                  setFieldValue('company_id', value?.id);
                  setcompanyVal(value);
                  setcompanyID(value?.id);
                }}
                style={{ marginBottom: '25px' }}
                onOpen={handleBlur}
                getOptionLabel={(options) => options.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(touched.company_id && errors.company_id)}
                    fullWidth
                    helperText={touched.company_id && errors.company_id}
                    name="company_id"
                    variant="outlined"
                    label="Coaching Provider"
                  />
                )}
                fullWidth={true}
              />
              <Stack direction="row" spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Full name*"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email*"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Stack>
              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  label="Password*"
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  label="Confirm Password*"
                  {...getFieldProps('password_confirmation')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowConfirmPassword} edge="end">
                          <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password_confirmation && errors.password_confirmation)}
                  helperText={touched.password_confirmation && errors.password_confirmation}
                />
              </Stack>

              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Phone*"
                  {...getFieldProps('phone_number')}
                  error={Boolean(touched.phone_number && errors.phone_number)}
                  helperText={touched.phone_number && errors.phone_number}
                  inputProps={{
                    min: 0,
                  }}
                />

                <Autocomplete
                  disablePortal
                  multiple
                  // defaultValue={def}
                  value={def}
                  id="combo-box-demo"
                  options={allClients}
                  onChange={(e, value) => {
                    // console.log('client_id', value);
                    // value?.map((item) => setFieldValue('client_id', item?.id));
                    setclientVal(value);
                    setDef(value);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(sc) => sc?.name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.client_id && errors.client_id)}
                      fullWidth
                      helperText={touched.client_id && errors.client_id}
                      name="client_id"
                      variant="outlined"
                      label="Client"
                    />
                  )}
                  fullWidth={true}
                />
              </Stack>
            </Stack>


            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
                marginTop: '0px !important',
              }}
            >
              <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: ' #344054',
                    }}
                  >
                    Qualifications*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  
                <Autocomplete
                  disablePortal
                  multiple
                  value={qual}
                  id="combo-box-demo"
                  options={allQualifications}
                    onChange={(e, value) => {
                      setqualificationval(value);
                      setQual(value);
                      setFieldValue('qualifications', value);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(sc) => sc?.qualification_name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.qualifications && errors.qualifications)}
                      fullWidth
                      helperText={touched.qualifications && errors.qualifications}
                      name="qualifications"
                      variant="outlined"
                      label="Qualifications"
                    />
                  )}
                  fullWidth={true}
                />
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: ' #344054',
                    }}
                  >
                    Universities*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    hiddenLabel
                    fullWidth
                    autoComplete="username"
                    type="text"
                    onChange={(e, value) => {
                      formik.resetForm();
                      setFieldValue('universities', value);
                      
                    }}
                    placeholder="Should be comma (,) separated"
                    // label="Should be comma (,) separated"
                    {...getFieldProps('universities')}
                    error={Boolean(touched.universities && errors.universities)}
                    helperText={touched.universities && errors.universities}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: ' #344054',
                    }}
                  >
                    Sector experience*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    hiddenLabel
                    fullWidth
                    autoComplete="username"
                    type="text"
                    placeholder="Should be comma (,) separated"
                    // label="Should be comma (,) separated"
                    {...getFieldProps('sector_experience')}
                    error={Boolean(touched.sector_experience && errors.sector_experience)}
                    helperText={touched.sector_experience && errors.sector_experience}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: ' #344054',
                      paddingBottom: '5px',
                    }}
                  >
                    Bio*
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#667085',
                    }}
                  >
                    Tell participants about yourself.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Editor
                    defaultEditorState={editorState}
                    editorState={editorState}
                    wrapperClassName={classes.wrapper_class}
                    editorClassName={classes.editor_class}
                    toolbarClassName={classes.toolbar_class}
                    onEditorStateChange={onEditorStateChange}
                    handlePastedText={handlePastedText}
                    toolbar={{
                      options: ['inline', 'blockType', 'list', 'link'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Stack>


            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: '25px' }} spacing={5}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={expLabel}
                value={yearsOfExp}
                // {...getFieldProps('years_of_experience')}
                onChange={(e, value) => {
                  console.log('value', value);
                  // Since we're only allowing single selection, value will be a single object or null
                  // const id = value ? value.id : null;
                  console.log(value.label);
                  setFieldValue('years_of_experience', value.label);
                }}
                onOpen={handleBlur}
                getOptionLabel={(expLabel) => expLabel.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(touched.years_of_experience && errors.years_of_experience)}
                    fullWidth
                    helperText={touched.years_of_experience && errors.years_of_experience}
                    name="years_of_experience"
                    variant="outlined"
                    label="Number of years working as a coach"
                  />
                )}
                fullWidth={true}
              />
              <Autocomplete
                multiple
                disablePortal
                
                id="combo-box-demo"
                value={sectors}
                options={allSectors}
                onChange={(e, value) => {
                  const arr = [];
                  value.map((item) => {
                    arr.push(item);
                  });
                  console.log('value', arr);
                  setSectors(arr);
                  setFieldValue('industry_experience_by_sector', arr);
                }}
                onOpen={handleBlur}
                getOptionLabel={(sectorLabel) => sectorLabel.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(touched.industry_experience_by_sector && errors.industry_experience_by_sector)}
                    fullWidth
                    helperText={touched.industry_experience_by_sector && errors.industry_experience_by_sector}
                    name="industry_experience_by_sector"
                    variant="outlined"
                    label="Industry experience by sector"
                  />
                )}
                fullWidth={true}
              />
            </Stack>

            <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
              <TextField
                fullWidth
                autoComplete="coaching_approaches"
                type="text"
                label="Main coaching approaches and methods*"
                {...getFieldProps('coaching_approaches')}
                error={Boolean(touched.coaching_approaches && errors.coaching_approaches)}
                helperText={touched.coaching_approaches && errors.coaching_approaches}
              />
            </Stack>
            <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
              <TextField
                fullWidth
                autoComplete="functional_areas_of_experience"
                type="text"
                label="Main functional areas of experience*"
                {...getFieldProps('functional_areas_of_experience')}
                error={Boolean(touched.functional_areas_of_experience && errors.functional_areas_of_experience)}
                helperText={touched.functional_areas_of_experience && errors.functional_areas_of_experience}
              />
            </Stack>

            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
                marginTop: '2rem',
              }}
            >
              <Typography variant="body1" className={classes.management}>
                Testimonials
              </Typography>
              <Typography variant="body1" className={classes.body1}>
                This is where you can manage your client testimonials.
              </Typography>

              <Grid container
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: { sm: 'center', xs:'flex-end'},
                  gap: '1rem',
                  margin: '50px 3px',
                  flexDirection:{xs:'column-reverse', sm:'row'}
                }}
                
              >
                <Box item>
                  <ToggleButtonGroup
                    variant="text"
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    color="primary"
                    className={classes.btncontainer}
                    sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', width: 'auto' }}
                  >
                    {detailsArray.map((btn, index) => (
                      <ToggleButton
                        key={index}
                        value={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          wordBreak: 'keep-all',
                        }}
                      >
                        Testimonial {index + 1}{' '}
                        <CloseIcon
                          onClick={() => {
                            testimonialDelete= true;
                            handleTestimonialDelete(index);
                          }}
                        />
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Box>
                <Button item
                  onClick={handleNewToggleButton}
                  disable={testimonialLoading}
                  startIcon={<AddIcon />}
                  className={classes.addButton}
                >
                  Add another
                </Button>
              </Grid>


              <Box sx={{
                width: '100%'
              }}>
                <Grid container spacing={2}  sx={{ width: '100%' }}>
                  <Grid item xs={12} sm={3}>
                    <Grid 
                      container 
                      className={classes.imageContainer} 
                      sx={{ width: '100%', flexDirection:{xs:'column !important'}, gap: '1rem' }}
                    >
                      <img
                        item
                        src={formik.values?.detail?.[alignment]?.picture ? formik.values?.detail?.[alignment]?.picture  : '/static/icons/Avatar.png'}
                        alt="testimonial"
                        style={{ width: '60px', height: '60px', borderRadius: '50%' }}
                      />
                      <Grid item xs={12} lg={6} className="uploadBox" sx={{ width: { xs: '100%', lg: '50%' } }}>
                        <Box sx={{ textAlign: 'center' }}>
                          <FileUploader
                            handleChange={(file) => handleChangeTestimonialPicture(file, alignment)}
                            types={fileTypes}
                          />
                          <p>{files[alignment] && `Image name: ${files[alignment]?.name}`}</p>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={9}>
                    <Grid container spacing={2}>
                      <Grid container  item spacing={2} xs={12} sm={9}> 
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            autoComplete="Name"
                            type="text"
                            label="Name*"
                            // value={formik.values?.detail?.[alignment]?.name}
                            name={`detail[${alignment}].test_name`}
                            {...getFieldProps(`detail[${alignment}].test_name`)}
                            error={Boolean(formik.touched?.detail?.[alignment]?.name && formik.errors?.detail?.[alignment]?.name)}
                            helperText={formik.touched?.detail?.[alignment]?.name && formik.errors?.detail?.[alignment]?.name}
                          /> 
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            autoComplete="Job/role"
                            type="text"
                            label="Job/role*"
                            name={`detail[${alignment}].role`}
                            {...getFieldProps(`detail[${alignment}].role`)}
                            error={Boolean(formik.touched?.detail?.[alignment]?.role && formik.errors?.detail?.[alignment]?.role)}
                            helperText={formik.touched?.detail?.[alignment]?.role && formik.errors?.detail?.[alignment]?.role}
                          />
                        </Grid>
                      </Grid>
                      
                      <Grid item xs={12} sm={3}>
                        <Grid container justifyContent={{ xs: 'flex-start', sm: 'flex-end' }} alignItems="center">
                          <Typography
                            variant="h5"
                            sx={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: '500',
                              fontSize: '16px',
                              lineHeight: '20px',
                              color: '#344054',
                              marginRight: '16px',
                            }}
                          >
                            Rating
                          </Typography>
                          <Box sx={{ textAlign: 'right' }}>
                            <RatingStar id="clickable" clickable rating={formik.values?.detail?.[alignment]?.rating} onRatingChange={onRatingChange} />
                            <Box
                              sx={{
                                lineHeight: '1.5',
                                fontSize: '0.75rem',
                                fontFamily: 'Public Sans,sans-serif',
                                fontWeight: '400',
                                textAlign: 'left',
                                marginTop: '3px',
                                marginRight: '14px',
                                marginBottom: '0',
                                marginLeft: '14px',
                                color: '#FF4842',
                              }}
                            >
                              {errors.rating}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} >
                        <TextField
                          fullWidth
                          autoComplete="Testimonial"
                          type="text"
                          label="Testimonial*"
                          name={`detail[${alignment}].testimonial`}
                          {...getFieldProps(`detail[${alignment}].testimonial`)}
                          error={Boolean(formik.touched?.detail?.[alignment]?.testimonial && formik.errors?.detail?.[alignment]?.testimonial)}
                          helperText={formik.touched?.detail?.[alignment]?.testimonial && formik.errors?.detail?.[alignment]?.testimonial}
                        />
                      </Grid>
                      
                    </Grid>
                  </Grid>
                </Grid>

              </Box>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent={isMobile ? "center" : "end"} sx={{ mt: 3, mb: 3 }}>
            <Button
              onClick={() => {
                navigate('/dashboard/coaches', { replace: true });
                clearData();
              }}
              variant="outlined"
              sx={{
                marginRight: '1rem',
                fontSize: isMobile ? '1.25rem' : '0.875rem', 
                padding: isMobile ? '16px 32px' : '8px 16px', 
                minWidth: isMobile ? '150px' : 'auto', 
              }}
              size="large"
            >
              Cancel
            </Button>
            <LoadingButton size="large" type="submit" variant="contained"
            sx={{
                marginRight: '1rem',
                fontSize: isMobile ? '1.25rem' : '0.875rem', 
                padding: isMobile ? '16px 32px' : '8px 16px', 
                minWidth: isMobile ? '150px' : 'auto', 
              }}
              loading={loadingState}>
              Save
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      <Toaster />
    </div>
  );
}
