import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import Iconify from 'src/components/Iconify';
import axios from 'axios';
import { base_url } from 'src/common';
import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FileUploader } from 'react-drag-drop-files';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';

const useStyle = makeStyles((theme) => ({
  modalMain: {
    width: '530px',

    // height: '530px',

    // backgroundColor: theme.palette.primary.dark,

    border: '1px solid rgba(239, 239, 239, 0.1)',

    borderRadius: '10px',

    borderColor: '#fff',
    padding: '15px 15px',
    '& .css-ypiqx9-MuiDialogContent-root': {
      padding: '15px 15px',
    },
    '& .heading': {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
    },
  },
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '38px',
    color: '#101828',
    paddingBottom: '32px',
  },
  management: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingTop: '32px',
    paddingBottom: '4px',
  },
  body1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
  },
  ParticipantDetails: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingBottom: '4px',
  },
  body2text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
    marginTop: '0px !important',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: '3rem',
    '& img': {
      width: '100%',
    },
    '& .imgBox': {
      width: '100px',
      height: '100px',
      borderRadius: '50%',
    },
    '& .uploadBox': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& label': {
        border: '0px !important',
        flexDirection: 'column !important',
        height: 'unset',
      },
      '& div': {
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        '& span': {
          // '&:last-child': {
          //   color: 'yellow !important',
          // },
        },
      },
    },
  },
  wrapper_class: {
    padding: '1rem',
    border: '1px solid #ccc',
    marginBottom: '1.5rem',
  },
  editor_class: {
    backgroundColor: 'lightgray',
    padding: '1rem',
    border: '1px solid #ccc',
  },
  toolbar_class: {
    border: '1px solid #ccc',
  },
}));

export default function AddCoach(props) {
  const company_id = sessionStorage.getItem('Company_id') || '';
  const navigate = useNavigate();
  const classes = useStyle();
  const [loadingState, setloadingState] = useState(false);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [ConvertedContent, setConvertedContent] = useState(null);
  const [allClients, setallClients] = useState([]);
  const [allQualifications, setallQualifications] = useState([]);
  const [file, setFile] = useState(null);
  const [Paths, setPaths] = useState(null);
  var formData = new FormData();

  const token = sessionStorage.getItem('libra_admin_token');
  const getClients = async () => {
    await axios
      .get(`${base_url}/api/clients`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallClients(res?.data?.data?.clients);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllQualifications = async () => {
    await axios
    .get(`${base_url}/api/qualifications`, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      setallQualifications(res?.data?.data?.qualifications);
      })
      .catch((err) => {
        console.log(err);
        });
  };
          useEffect(() => {
            getClients();
            getAllQualifications();
          }, []);
  const fileTypes = ['JPEG', 'PNG', 'JPG'];

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Enter proper email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),

    phone_number: Yup.string().matches(/^\d+$/, 'Phone have digits only').required('Phone no. is required'),
    // qualifications: Yup.string().required('Qualification is required'),
    universities: Yup.string().required('Universities is required'),
    sector_experience: Yup.string().required('Sector experience is required'),
    // client_id: Yup.string().required('Client name is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      phone_number: '',
      // profile_picture: null,
      qualifications: [],
      universities: '',
      sector_experience: '',
      biography: '',
      client_ids: [],
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setloadingState(true);

      formData.append('name', values?.name);
      formData.append('email', values?.email);
      formData.append('password', values?.password);
      formData.append('password_confirmation', values?.password_confirmation);
      formData.append('phone_number', values?.phone_number);
      formData.append('qualifications', values?.qualifications);
      formData.append('universities', values?.universities);
      formData.append('sector_experience', values?.sector_experience);
      formData.append('biography', values?.biography);
      formData.append('client_ids', values?.client_ids);
      formData.append('profile_picture', file);
      formData.append('company_id', company_id);

      axios
        .post(`${base_url}/api/coaches`, formData, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          console.log("Coach Response",res);
          if (res?.data?.status === true) {
            toast.success('Coach added successfully');
            clearData();
            setTimeout(() => {
              clearData();
              navigate('/dashboard/coaches', { replace: true });
              setloadingState(false);
            }, 2000);
          }
        })
        .catch((err) => {
          setloadingState(false);

          if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          } else {
            toast.error('Server Error');
          }
        });
    },
  });

  const { errors, touched, handleBlur, setFieldValue, resetForm, handleSubmit, getFieldProps } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };
  const clearData = () => {
    resetForm();
  };
  const handleChange = (file) => {
    setFile(file);
    setPaths(URL.createObjectURL(file));
    // setFieldValue('profile_picture', file?.File);
  };
  function onEditorStateChange(state) {
    setEditorState(state);
    convertContentToHTML();
  }
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML({
      // styleToHTML: (style) => {
      //   if (style === "BOLD") {
      //     return <span style={{ color: "blue" }} />;
      //   }
      // },
      // blockToHTML: (block) => {
      //   if (block.type === "PARAGRAPH") {
      //     return <p />;
      //   }
      // },
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return (
            <a href={entity.data.url} target={entity?.data?.targetOption}>
              {originalText}
            </a>
          );
        }
        return originalText;
      },
    })(editorState.getCurrentContent());

    setConvertedContent(currentContentAsHTML);
    setFieldValue('biography', currentContentAsHTML);
  };
  return (
    <div>
      <Typography variant="h1" className={classes.heading}>
        Coach
      </Typography>
      <Divider />
      <Typography variant="body1" className={classes.management}>
        Overview
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        This is where you can manage the coach.
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {/* <Typography variant="body1" className={classes.ParticipantDetails}>
              Participant details
            </Typography>
            <Typography variant="subtitle1" className={classes.body2text}>
              Add details for the particpiant.
            </Typography> */}

            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
                marginTop: '0px !important',
              }}
            >
              <Box className={classes.imageContainer}>
                <Box className="imgBox">
                  <img
                    src={Paths ? `${Paths}` : '/static/icons/Avatar.png'}
                    // src="http://ec2-54-237-56-13.compute-1.amazonaws.com/public/storage/users/profile_images/fBm0o7vi4X6pSGwIN9TyMJm3paANqjdMw2y9cd33.png"
                    alt="coach"
                  />
                </Box>
                <Box className="uploadBox">
                  <Box sx={{ textAlign: 'center' }}>
                    <FileUploader handleChange={handleChange} types={fileTypes} />
                    <p>{file && `Image name: ${file?.name}`}</p>
                  </Box>
                </Box>
              </Box>
              <Stack direction="row" spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Full name*"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email*"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Stack>
              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  label="Password*"
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  label="Confirm Password*"
                  {...getFieldProps('password_confirmation')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowConfirmPassword} edge="end">
                          <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password_confirmation && errors.password_confirmation)}
                  helperText={touched.password_confirmation && errors.password_confirmation}
                />
              </Stack>

              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="tel"
                  label="Phone*"
                  {...getFieldProps('phone_number')}
                  error={Boolean(touched.phone_number && errors.phone_number)}
                  helperText={touched.phone_number && errors.phone_number}
                  inputProps={{
                    min: 0,
                  }}
                />

                <Autocomplete
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  options={allClients}
                  onChange={(e, value) => {
                    const ids = [];
                    value.map((item) => {
                      ids.push(item.id);
                    });
                    setFieldValue('client_ids', ids);
                  }}
                  onOpen={handleBlur}
                  // renderOption={(option) => <>{option.name}</>}
                  getOptionLabel={(allClients) => allClients.name}
                  // includeInputInList
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // error={Boolean(touched.client_id && errors.client_id)}
                      fullWidth
                      // helperText={touched.client_id && errors.client_id}
                      name="client_id"
                      variant="outlined"
                      label="Client"
                    />
                  )}
                  fullWidth={true}
                />
              </Stack>
            </Stack>
            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
                marginTop: '0px !important',
              }}
            >
              <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: ' #344054',
                    }}
                  >
                    Qualifications*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  {/* <TextField
                    hiddenLabel
                    fullWidth
                    autoComplete="username"
                    type="text"
                    placeholder="Should be comma (,) separated"
                    // label="Should be comma (,) separated"
                    {...getFieldProps('qualifications')}
                    error={Boolean(touched.qualifications && errors.qualifications)}
                    helperText={touched.qualifications && errors.qualifications}
                  /> */}
                  <Autocomplete
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  options={allQualifications}
                  onChange={(e, value) => {
                    console.log('value', value);
                    const ids = [];
                    value.map((item) => {
                      ids.push(item.id);
                    });
                    setFieldValue('qualifications', ids);
                  }}
                  onOpen={handleBlur}
                  // renderOption={(option) => <>{option.name}</>}
                  getOptionLabel={(allQualifications) => allQualifications.qualification_name}
                  // includeInputInList
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.qualifications && errors.qualifications)}
                      fullWidth
                      helperText={touched.qualifications && errors.qualifications}
                      name="qualifications"
                      variant="outlined"
                      label="Qualifications"
                    />
                  )}
                  fullWidth={true}
                />
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: ' #344054',
                    }}
                  >
                    Universities*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    hiddenLabel
                    fullWidth
                    autoComplete="username"
                    type="text"
                    placeholder="Should be comma (,) separated"
                    // label="Should be comma (,) separated"
                    {...getFieldProps('universities')}
                    error={Boolean(touched.universities && errors.universities)}
                    helperText={touched.universities && errors.universities}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: ' #344054',
                    }}
                  >
                    Sector experience*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    hiddenLabel
                    fullWidth
                    autoComplete="username"
                    type="text"
                    placeholder="Should be comma (,) separated"
                    // label="Should be comma (,) separated"
                    {...getFieldProps('sector_experience')}
                    error={Boolean(touched.sector_experience && errors.sector_experience)}
                    helperText={touched.sector_experience && errors.sector_experience}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: ' #344054',
                      paddingBottom: '5px',
                    }}
                  >
                    Bio*
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#667085',
                    }}
                  >
                    Tell participants about yourself.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Editor
                    defaultEditorState={editorState}
                    editorState={editorState}
                    wrapperClassName={classes.wrapper_class}
                    editorClassName={classes.editor_class}
                    toolbarClassName={classes.toolbar_class}
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                      options: ['inline', 'blockType', 'list', 'link'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mt: 3 }}>
            <Button
              onClick={() => {
                navigate('/dashboard/coaches', { replace: true });
                clearData();
              }}
              variant="outlined"
              sx={{ marginRight: '1rem' }}
              size="large"
            >
              Cancel
            </Button>
            <LoadingButton size="large" type="submit" variant="contained" loading={loadingState}>
              Save
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      <Toaster />
    </div>
  );
}
